import { combineReducers } from 'redux';

import category from "./category";
import auth from './auth';
import course from './course';
import exercise from './exercise';
import forum from './forum';
import information from './information';
import layout from './layout';
import navbar from './navbar';
import notification from './notification';
import packages from './package.reducer';
import result from './result';
import time from './time';
import withdrawal from './withdrawal';

const rootReducer = combineReducers({
	category,
	auth,
	course,
	exercise,
	forum,
	information,
	layout,
	navbar,
	notification,
	packages,
	result,
	time,
	withdrawal
});

export default rootReducer;
