const initialState = {
	transactions: [],
	withdrawals: [],
	withdrawal: {},
	info: {},
	balance: 0,
	card: {},
	banks: [],
	reloadStatus: {
		card : false,
		info : false
	}
};

const withdrawalReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_REFERRAL_TRANSACTION':
			return {
				...state,
				transactions: action.payload
			};
		case 'SET_REFERRAL_WITHDRAWAL_HISTORY':
			return {
				...state,
				withdrawals: action.payload
			};
		case 'SET_REFERRAL_WITHDRAWAL_DETAIL':
			return {
				...state,
				withdrawal: action.payload
			};
		case 'SET_REFERRAL_INFO':
			return {
				...state,
				info: action.payload,
			};
		case 'SET_REFERRAL_MY_CARD':
			return {
				...state,
				card: action.payload,
			};
		case 'SET_REFERRAL_BANK_LIST':
			return {
				...state,
				banks: action.payload,
			};
		case 'SET_REFERRAL_BALANCE':
			return {
				...state,
				balance: action.payload,
			};
		case 'RELOAD_REFERRAL_CARD_SECTION':
			return {
				...state,
				reloadStatus: {
					...state.reloadStatus,
					card: action.payload
				},
			};
		case 'RELOAD_REFERRAL_INFO_SECTION':
			return {
				...state,
				reloadStatus: {
					...state.reloadStatus,
					info: action.payload
				},
			};
		default:
			return state;
	}
};

export default withdrawalReducer;
