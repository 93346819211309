/* eslint-disable no-case-declarations */
const initialState = {
	questions: [],
	cursor: {},
	question: { author: {} },
	tags: [],
};

const forumReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_FORUM_QUESTIONS':
			const { flushQuestion, cursor, questions } = action.payload;

			const currentQuestion = flushQuestion ? [] : [...state.questions];

			return {
				...state,
				questions: [...currentQuestion, ...questions],
				cursor: cursor,
			};
		case 'SET_FORUM_QUESTION':
			return {
				...state,
				question: action.payload,
			};
		case 'SET_FORUM_TAGS':
			return {
				...state,
				tags: action.payload,
			};

		default:
			return state;
	}
};

export default forumReducer;
