import React from 'react';
import ReactDOM from 'react-dom';
import Spinner from './@core/components/spinner/Fallback-spinner';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from '@store/storeConfig/store';
import { Suspense, lazy } from 'react';
import { ThemeContext } from './utility/context/ThemeColors';
import { ToastContainer } from 'react-toastify';

import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

import 'react-perfect-scrollbar/dist/css/styles.css';
import '@styles/react/libs/toastify/toastify.scss';

import './@core/assets/fonts/feather/iconfont.css';
import './@core/components/ripple-button';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

import * as serviceWorker from './serviceWorker';

const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Suspense fallback={<Spinner />}>
				<ThemeContext>
					<LazyApp />
					<ToastContainer newestOnTop />
				</ThemeContext>
			</Suspense>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);

serviceWorker.unregister();
