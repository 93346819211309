/* eslint-disable no-case-declarations */
const initialState = {
	currentQuestion: 0,
	result: [],
	type: null,
};

const resultReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_RESULT':
			const { question, type } = action.payload;

			return {
				...state,
				result: question,
				type,
			};
		case 'SET_RESULT_CURRENT_QUESTION':
			return {
				...state,
				currentQuestion: action.payload,
			};

		default:
			return state;
	}
};

export default resultReducer;
