const initialState = {
	exercises: {},
	results: {},
	result: null,
	selectedExercise: null,
	session: {
		exercise: {},
		answers: [],
		labels: [],
		questions: [],
		selectedPackage: {},
		selectedTest: {},
		session: null,
		sessionAlertModalVisible: false,
		status: {
			isDone: false,
			redirectUrl: false,
			waitRedirect: 0
		}
	}
};

const exerciseReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_EXERCISES':
			return {
				...state,
				exercises: {
					...state.exercises,
					...action.payload
				}
			};
		case 'SET_EXERCISE_RESULTS':
			return {
				...state,
				results: {
					...state.results,
					...action.payload,
				}
			};
		case 'SET_EXERCISE_RESULT':
			return {
				...state,
				result: action.payload,
			};
		case 'SET_PASSING_GRADE_EXPLANATION_TEST':
			return {
				...state,
				result: {
					...state.result,
					passingGrade: action.payload,
					selectedCategoryPassingGrade: Object.keys(action.payload)[0]
				}
			};
		case 'SET_RESULT_CURRENT_QUESTION':
			return {
				...state,
				result: {
					...state.result,
					currentQuestion:action.payload
				}
			};
		case 'SET_REDIRECT_URL_SUCCESS_TEST':
			return {
				...state,
				session: {
					...state.session,
					status: {
						...state.session.status,
						isDone: true,
						redirectUrl: action.payload.url,
						resultsUrl: action.payload.resultsUrl,
						waitRedirect: action.payload.waitUrl
					}
				}
			};
		case 'SET_ANSWERS':
			return {
				...state,
				session: {
					...state.session,
					answers: action.payload
				}
			};
		case 'SET_TEST_DOING_QUESTIONS': {
			const { questions, exercise, section } = action.payload;
			if(state.session.session.section) {
				return {
					...state,
					session: {
						...state.session,
						questions,
						questionsLength: questions.length,
						exercise: exercise,
						session: {
							...state.session.session,
						}
					}
				};
			} else {
				return {
					...state,
					session: {
						...state.session,
						questions,
						questionsLength: questions.length,
						exercise: exercise,
						session: {
							...state.session.session,
							section
						}
					}
				};
			}
		}
		case 'SET_SESSION_ALERT_MODAL_VISIBLE':
			return {
				...state,
				session: {
					...state.session,
					sessionAlertModalVisible: action.payload
				}
			};
		case 'SET_SELECTED_CATEGORY_PASSING_GRADE':
			return {
				...state,
				result: {
					...state.result,
					selectedCategoryPassingGrade: action.payload,
				}
			};
		case 'SET_SELECTED_TEST':
			return {
				...state,
				session: {
					...state.session,
					selectedTest: action.payload.test,
					selectedPackage: action.payload.package
				}
			};
		case 'SET_TEST_SESSION':
			return {
				...state,
				session: {
					...state.session,
					session: action.payload
				}
			};
		case 'SET_CURRENT_SECTION':
			return {
				...state,
				session: {
					...state.session,
					session: {
						...state.session.session,
						section: {
							...state.session.session.section,
							currentSection: action.payload.sectionKey,
							startSectionTime: action.payload.startSectionTime
						}
					}
				}
			};
		case 'REMOVE_SESSION':
			return {
				...state,
				session: {
					...initialState.session,
				}
			};
		default:
			return state;
	}
};

export default exerciseReducer;
