/* eslint-disable no-case-declarations */
const initialState = {
	userData: {},
};

const authReducer = (state = initialState, payload) => {
	switch (payload.type) {
		case 'LOGIN':
			return {
				...state,
				userData: payload.data,
				[payload.config.storageTokenKeyName]:
					payload[payload.config.storageTokenKeyName],
				[payload.config.storageRefreshTokenKeyName]:
					payload[payload.config.storageRefreshTokenKeyName],
			};
		case 'SET_USER':
			return {
				...state,
				userData: { user: payload.data },
			};
		case 'LOGOUT':
			const obj = { ...payload };
			delete obj.type;
			return { ...state, userData: {}, ...obj };
		default:
			return state;
	}
};

export default authReducer;
