const initialState = {
	question: {}
};

const categoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_CATEGORY_QUESTION':
			return {
				...state,
				question : action.payload
			};
		default:
			return state;
	}
};

export default categoryReducer;
