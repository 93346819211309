/* eslint-disable no-case-declarations */
const initialState = {
	flashSales: [],
	informations: [],
	total: 0,
};

const informationReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_INFORMATION':
			const { informations, total } = action.payload;

			return {
				...state,
				informations,
				total,
			};
		case 'SET_FLASHSALE':
			return {
				...state,
				flashSales: action.payload,
			};
		default:
			return state;
	}
};

export default informationReducer;
