const initialState = {
	serverTime: new Date().getTime(),
	deltaTime: 0
};

const timeReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_SERVER_TIME': {
			return {
				...state,
				serverTime: action.payload,
				deltaTime: (new Date().getTime()) - (new Date(action.payload).getTime())
			};
		}
		default:
			return state;
	}
};

export default timeReducer;
