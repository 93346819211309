const initialState = {
	notification: null,
	notifications: [],
	slicedNotifications: [],
};

const notificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_NOTIFICATIONS':
			return {
				...state,
				notifications: action.payload,
			};
		case 'SET_SLICED_NOTIFICATIONS':
			return {
				...state,
				slicedNotifications: action.payload,
			};
		case 'SET_NOTIFICATION':
			return {
				...state,
				motification: action.payload,
			};

		default:
			return state;
	}
};

export default notificationReducer;
