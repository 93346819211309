const initialState = {
	courses: {},
	course: {},
};

const courseReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_COURSES':
			return {
				...state,
				courses: {
					...state.courses,
					...action.payload
				}
			};
		case 'SET_COURSE':
			return {
				...state,
				course: action.payload,
			};
		default:
			return state;
	}
};

export default courseReducer;
