/* eslint-disable no-case-declarations */
const initialState = {
	packages: {
		enrollment: [],
		my: [],
	},
	orders: {
		orders: [],
		cursor: [],
	},
	packageDetail: {},
	course: [],
	voucher: {},
};

const packageReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_MY_PACKAGES':
			return {
				...state,
				packages: {
					...state.packages,
					my: action.payload,
				},
			};
		case 'SET_MY_ORDER_PACKAGES':
			const {orders, cursor, isFlush} = action.payload;
			const currentOrder = isFlush ? [] : [...state.orders.orders];
			return {
				...state,
				orders: {
					...state.orders,
					orders: [...currentOrder, ...orders],
					cursor: cursor
				}
			};
		case 'SET_PACKAGES':
			return {
				...state,
				packages: {
					...state.packages,
					enrollment: action.payload,
				},
			};
		case 'SET_PACKAGE':
			return {
				...state,
				packageDetail: action.payload,
			};

		case 'SET_VOUCHER':
			return {
				...state,
				voucher: action.payload,
			};

		case 'SET_INITIAL_VOUCHER':
			return {
				...state,
				voucher: {},
			};

		default:
			return state;
	}
};

export default packageReducer;
